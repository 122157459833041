import './scss/index.scss';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
console.log('APP PART');


(() => {
    if (
        !document.querySelector('.faq .intro p')
    ) {
        return;
    }
  
    gsap.registerPlugin(ScrollTrigger);
  
    gsap.to('.faq .intro p', {
        backgroundPositionX: '0%',
        stagger: 1,
        scrollTrigger: {
            trigger: '.faq .intro',
            scrub: 1,
            start: 'top center',
            end: 'bottom center'
        }
    });
  })();